import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
	postMessageToRN,
	useRNHandler,
} from "../@utility/react-native.utility";

let timeout: NodeJS.Timeout;

const ReactNativeDeepLink = () => {
	const history = useHistory();
	// const location = useLocation();

	//this part is @deprecated
	const [rnLink, setRnLink] = React.useState<string | undefined>();

	//this part is @deprecated
	React.useEffect(() => {
		if (timeout) {
			clearInterval(timeout);
		}

		timeout = setInterval(() => {
			if (rnLink !== (window as any).ReactNativeDeepLink) {
				setRnLink((window as any).ReactNativeDeepLink);
			}
		}, 1);

		return () => {
			clearInterval(timeout);
		};
	}, [rnLink]);

	//this part is @deprecated
	React.useEffect(() => {
		if (rnLink) {
			// console.log("-rnLink--", rnLink);
			// const timeIndex = rnLink.indexOf("?time");
			// const finalLink = timeIndex > -1 ? rnLink.substring(0, timeIndex) : rnLink;
			// if(location.pathname !== finalLink) {
			history.push(rnLink);
			// }
		}
	}, [rnLink, history]);

	React.useEffect(() => {
		const timeout = setInterval(() => {
			if ((window as any)?.isReactNativeApp) {
				postMessageToRN("readyToNavigateInWebview", {
					isReady: true,
				});
				clearInterval(timeout);
			}
		}, 200);

		return () => {
			clearInterval(timeout);
		};
	}, []);

	const navigateToNewLink = useCallback(
		(payload: { to: string }) => {
			history.push(payload.to);
		},
		[history]
	);

	useRNHandler("navigateInWebView", navigateToNewLink);

	return null;
};

export default ReactNativeDeepLink;
