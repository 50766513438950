import { useReactiveVar } from "@apollo/client";
import { userInfoVar } from "../../App";
import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";

export const usePosthogIdentifyUser = () => {
	const posthog = usePostHog();

	const currUser = useReactiveVar(userInfoVar);

	useEffect(() => {
		if (currUser?.id) {
			// Identify sends an event, so you want may want to limit how often you call it
			posthog?.identify(currUser?.id, {
				email: currUser?.email,
				phoneNumber: currUser?.phoneNumber,
				verified: currUser?.verified,
				emailVerified: currUser?.emailVerified,
				phoneVerified: currUser?.phoneVerified,
			});
		}

		// posthog.reset(true)
	}, [
		posthog,
		currUser?.id,
		currUser?.email,
		currUser?.phoneNumber,
		currUser?.verified,
		currUser?.emailVerified,
		currUser?.phoneVerified,
	]);

	useEffect(() => {
		posthog?.capture("$set", {
			$set: {
				fullname: currUser?.fullname,
				subscription: currUser?.subscription,
				nativeLanguage: currUser?.nativeLanguage,
				currentStreak: currUser?.currentStreak,
				languageLevel: currUser?.languageLevel,
				isPushNotifActive:
					!!currUser?.pushNotifTokens?.[0]?.installationId,
			},
			// $set_once: { initial_url: "/blog" },
		});
	}, [posthog, currUser]);
};
