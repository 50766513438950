import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ActionSheet from "../../components/actionSheet/ActionSheet";
import { isAndroid, isIOS } from "react-device-detect";
import { IconWrapper } from "../../theme/icons/Icon";
import css from "./ReactNativeForceUpdate.module.css";

let timeout: NodeJS.Timeout;
const ReactNativeForceUpdate = () => {
	const [isModalVisible, setIsModalVisible] = React.useState(true);
	const [isAppInitialized, setAppInitialized] = React.useState(false);

	const handleCancel = () => {
		//We don't let user to close the sheet
		setIsModalVisible(true);
	};

	const link = isAndroid
		? "https://play.google.com/store/apps/details?id=com.metalearnlang.app"
		: isIOS
		? "https://apps.apple.com/tr/app/metalearnlang/id6444627980"
		: "";

	useEffect(() => {
		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			setAppInitialized(true);
		}, 3000);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	if (!(window as any).isReactNativeApp || !isAppInitialized) {
		return null;
	}

	if (
		(window as any).isReactNativeApp &&
		(window as any).isRNInAdsVersion &&
		(window as any).ReactNativeWebAppLogin &&
		(window as any).ReactNativeGoogleAnalytics &&
		(window as any).isReactNativeMessaging
	) {
		return null;
	}

	return (
		<ActionSheet
			hasOverlay
			visible={isModalVisible}
			onCancel={handleCancel}
			title={
				<>
					<IconWrapper name="refresh" size={19} color="#575D62" />
					<span className={css.titleText}>Force Update</span>
				</>
			}
		>
			<section className={css.contentWrapper}>
				<p>
					{
						"Oops Sorry! We don't support the old version anymore. So you need to update the app!"
					}
				</p>
				<div className={css.button}>
					<Link
						target="_blank"
						className={css.link}
						to={{ pathname: link }}
					>
						{"Let's Go and Update!"}
					</Link>
				</div>
			</section>
		</ActionSheet>
	);
};

export default ReactNativeForceUpdate;
