import axios from "axios";

export async function uploadPartsInQueue(
	file: File,
	partUrls: { signedUrl: string; partNumber: number }[],
	onProgress?: (uploadedBytes: number, totalBytes: number) => void,
	abortController?: AbortController
) {
	const partSize = 5 * 1024 * 1024; // 5 MB
	const totalBytes = file.size; // Total file size
	let uploadedBytes = 0; // Tracks cumulative uploaded bytes

	const uploadParts: {
		ETag: string;
		PartNumber: number;
	}[] = [];

	let index = 0;
	for (const { signedUrl, partNumber } of partUrls) {
		const start = index * partSize;
		const end = Math.min(start + partSize, file.size);

		const partData = file.slice(start, end);

		const res = await axios
			.put(signedUrl, partData, {
				headers: {
					"Content-Type": "application/octet-stream", // Adjust if needed
				},
				signal: abortController?.signal, // Abort signa
				onUploadProgress: (progressEvent) => {
					if (onProgress) {
						onProgress(
							uploadedBytes + progressEvent.loaded,
							totalBytes
						); // Trigger progress callback
					}

					if (
						progressEvent.loaded > 0 &&
						progressEvent.loaded === progressEvent.total
					) {
						uploadedBytes += progressEvent.loaded;
					}
				},
			})
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(`Failed to upload part ${partNumber}`);
				}

				return {
					ETag: response.headers["etag"]?.replaceAll('"', "") || "",
					PartNumber: partNumber,
				};
			})
			.catch((error) => {
				console.error(`Error uploading part ${partNumber}:`, error);
				throw error;
			});

		uploadParts.push(res);

		index++;
	}

	return uploadParts;
}
