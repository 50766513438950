import { MutationFunctionOptions, useMutation } from "@apollo/client";
import BUY_PLAN from "./buy-plan.gql";
import { BuyPlanData, BuyPlanVariables } from "./buy-plan.types";
import useErrorNotification from "../../../../helper/hooks/useErrorNotification";
import { useMemo } from "react";

export function useBuyPlanMutation() {
	const [buyPlan, { loading, data }] = useMutation<
		BuyPlanData,
		BuyPlanVariables
	>(BUY_PLAN);

	const dataForNotify = useMemo(() => {
		const res = {
			result: !!data?.buyPlan.url,
			errors: data?.buyPlan?.errors,
		};

		return data ? res : undefined;
	}, [data]);

	useErrorNotification(dataForNotify);

	function buyPlanHandler(
		variables: BuyPlanVariables,
		options?: MutationFunctionOptions<BuyPlanData, BuyPlanVariables>
	) {
		console.log("BuyPlanAPIRequested", "Buy Plan API Requested!");
		buyPlan({
			variables,
			...options,
		});
	}

	return {
		loading,
		buyPlan: buyPlanHandler,
		data,
	};
}
