import React, { useState } from "react";
// import Streak from "../../screens/streak/Streak";
import Button from "../../theme/button/Button";
import { IconWrapper } from "../../theme/icons/Icon";
import Text from "../../components/text/Text";
import { Popup } from "antd-mobile";
import css from "./popup-manager.module.css";
import WelcomePresentPopup from "./welcome-present-popup/welcome-present-popup";
import { useReactiveVar } from "@apollo/client";
import { currentPopupVar } from "./popup-manager.state";
import { PopupManagerTemplates } from "./popup-manager.constants";
import { usePostHog } from "posthog-js/react";

const PopupManager = () => {
	const currentPopup = useReactiveVar(currentPopupVar);

	const { template, title, visible } = currentPopup || {};

	const posthog = usePostHog();

	const handleClose = () => {
		currentPopupVar({
			...currentPopup,
			visible: false,
		});
		posthog?.capture("trialPresentRefused");
		localStorage.setItem("trialPresentRefused", "yes");
	};

	return (
		<section>
			<Popup
				position="bottom"
				visible={visible}
				bodyStyle={{
					width: "100%",
				}}
				destroyOnClose
			>
				{template === PopupManagerTemplates.WelcomePresent && (
					<WelcomePresentPopup />
				)}
				<Button
					onClick={handleClose}
					className={css.closeBtn}
					size="large"
					// disabled={!isThereNextAction && type !== "failed"}
					emptySpan={false}
				>
					<IconWrapper name="closeModal" size={20} />
					<Text type="title" className={css.title}>
						{title}
					</Text>
				</Button>
			</Popup>

			{/*<Button
				onClick={handleOpen}
				// className={css.closeBtn}
				size="large"
				// disabled={!isThereNextAction && type !== "failed"}
				emptySpan={false}
			>
				Open Modal
			</Button>*/}
		</section>
	);
};

export default PopupManager;
