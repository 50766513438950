import { gql } from "@apollo/client";

export const CAMPAIGN_FIELDS_FRAGMENT = gql`
	fragment CampaignFields on Campaign {
		id
		campaignName
		campaignType
		status
		pushNotificationCampaign {
			pushNotifTitle
			pushNotifSubTitle
			pushNotifContent
			pushNotifImage
			androidNotificationChannel
			data {
				key
				value
			}
		}
		emailCampaign {
			subject
			body
			htmlBody
			attachments
		}
		smsCampaign {
			messageContent
		}
		inAppMessagingCampaign {
			messageTitle
			messageContent
			messageImage
		}
		targetUsers {
			affiliateStatus
			specificAffiliate
			createdDateRange
			currentStreak
			emailVerified
			excludeNativeLanguage
			hasUsedTrialVersion
			isActive
			isAffiliate
			level
			nativeLanguage
			phoneVerified
			roles
			subscriptionPlans
			studyTimeReminder
			subscriptionExpiryDays
			subscriptionType
			verificationStatus
			userIds
		}
		schedulingSettings {
			endAt
			isStartNow
			recurringTimes
			isCustomCronExpression
			scheduleType
			startAt
		}
		inAppNotificationSettings {
			showInAppNotifications
			notificationGroup
			title
			summary
			inAppMessagesListData {
				id
				name
				type
				media {
					id
					filename
					filepath
					type
					mimetype
					title
				}
				content
				contentType
				title
				actionType
				actionData
				uniqueUsersClicksNumber
				relatedActionIds
			}
		}
		campaignStats {
			renderedTimes
			sendsNumber
			clicksNumber
			eligiblesNumber
		}
		createdAt
		updatedAt
	}
`;
