import { useReactiveVar } from "@apollo/client";
import { useBuyPlanMutation } from "../graphQL/buy-plan/buy-plan.mutation";
import { userInfoVar } from "../../../App";
import { CurrentUser } from "../../../graphQL/user/UserTypes";
import { updateUserInfo } from "../../../helper/utilities";
import { USER_SUBSCRIPTION_PREMIUM } from "../../../graphQL/user/UserConstants";
import { CLIENT_URL } from "../../../config";
import { useCBStartTransactionMutation } from "../graphQL/cb-start-transaction/cb-start-transaction.mutation";
import { postMessageToRN } from "../../../reactNative/@utility/react-native.utility";
import useUpdatedUser from "../../../graphQL/user/hooks/useUpdatedUser";
import { currentPopupVar } from "../../../features/popup-manager/popup-manager.state";
import { useHistory } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

export function useHandlePayment(
	selectedPlanId?: string,
	selectedPlanName?: string,
	appliedCouponCode?: string
) {
	const userInfo = useReactiveVar(userInfoVar);
	const currentPopup = useReactiveVar(currentPopupVar);
	const history = useHistory();

	const { refetch, loading: refetchUserLoading } = useUpdatedUser();

	const {
		loading: buyPlanLoading,
		buyPlan,
		data: buyPlanData,
	} = useBuyPlanMutation();

	const posthog = usePostHog();

	const { cbStartTransaction, loading: cbStartTransactionLoading } =
		useCBStartTransactionMutation();

	const isRNCafeBazaar = (window as any)?.isRNCafeBazaar;

	const handlePayment = () => {
		if (selectedPlanId) {
			if (!isRNCafeBazaar) {
				buyPlan(
					{
						planId: selectedPlanId,
						couponCode: appliedCouponCode,
					},
					{
						onCompleted({ buyPlan }) {
							if (buyPlan?.url) {
								if (buyPlan?.url === "/") {
									(async function () {
										try {
											await refetch();
											if (currentPopup?.visible) {
												currentPopupVar({
													title: "",
													template: undefined,
													visible: false,
												});
												posthog?.capture(
													"trialPresentSuccess"
												);
											} else {
												history.push("/");
											}
										} catch (err) {
											console.log(
												"Something went wrong",
												err
											);
											if (currentPopup?.visible) {
												posthog?.capture(
													"trialPresentFailed"
												);
											}
										}
									})();
								} else {
									// redirect to buyPlan?.url
									//@TODO: we should remove this update
									// as we just start to payment and
									// maybe it would be failed.
									const currentUserInfo = {
										...userInfo,
										subscription: USER_SUBSCRIPTION_PREMIUM,
									} as CurrentUser;

									updateUserInfo({
										needToRnLogin: true,
										currentUserInfo,
										prevUserInfo: userInfo,
									});

									window.location.replace(
										buyPlan?.url /*=== "/"
											? CLIENT_URL + "/"
											: buyPlan?.url*/
									);
								}
							} else {
								if (currentPopup?.visible) {
									posthog?.capture("trialPresentFailed");
								}
							}
						},
						onError(err) {
							if (currentPopup?.visible) {
								posthog?.capture("trialPresentFailed");
							}
						},
					}
				);
			} else {
				cbStartTransaction(
					{
						planId: selectedPlanId,
						couponCode: appliedCouponCode,
					},
					{
						onCompleted({ CBStartTransaction }) {
							if (
								CBStartTransaction?.result &&
								CBStartTransaction.transactionId &&
								selectedPlanName
							) {
								postMessageToRN("cbStartPayment", {
									productId: selectedPlanName,
									discountToken:
										CBStartTransaction.discountToken,
									transactionId:
										CBStartTransaction.transactionId,
								});
							}
						},
					}
				);
			}
		}
	};

	return {
		refetchUserLoading,
		handlePayment,
		buyPlanLoading,
		buyPlanData,
		cbStartTransactionLoading,
	};
}
