import Quill from "quill";
import {
	ASSETS_FOLDER,
	AWS3_BASE_URL,
	COURSE_IMAGES_FOLDER,
	LESSON_MEDIA_FOLDER,
	NOTE_IMAGES_FOLDER,
	PROFILE_IMAGES_FOLDER,
} from "../config";
import {
	isPlayerMutedVar,
	isPlayerPlayingVar,
	isPlayerReadyVar,
	isPlayerRepeatModeVar,
	playbackRateVar,
	playerCurrentFrameVar,
	playerDurationVar,
	playerPlayedSecondsTimeVar,
	playerSeekToVar,
	userInfoVar,
} from "../App";
import { LessonData, LessonStats } from "../graphQL/lesson/LessonTypes";
import { EmbededMedia } from "../graphQL/media/MediaTypes";
import { LanguagesList } from "../graphQL/translation/TranslationTypes";
import languages from "./languages/languages.json";
import { PracticeType } from "../graphQL/userLessonStats/UserLessonStatsTypes";
import { CurrentUser, User } from "../graphQL/user/UserTypes";
import {
	CUSTOM_FINISH_UNIT_EVENT,
	CUSTOM_GAIN_STARS_EVENT,
	CUSTOM_SIGNUP_EVENT,
	CUSTOM_STREAK_EVENT,
} from "../features/google-analytics/google-analytics.constants";
import { isEqual } from "lodash";
import { translate } from "../i18n";
import i18n from "i18n-js";
import jalaali from "jalaali-js";
import dayjs from "dayjs";
import { selectTheBestAWS } from "../services/operation-location-base";
import { trackEventPosthog } from "../features/google-analytics/posthog";

export const getEditorHTML = (inputDelta: any) => {
	const tempCont = document.createElement("div");
	new Quill(tempCont).setContents(inputDelta);
	return tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
};

export function getRandomInt(max: number) {
	return Math.floor(Math.random() * Math.floor(max));
}

export const sortByOrder = <T extends { order: number }>(arr: T[]) => {
	return arr.sort((a: T, b: T) => {
		if (a.order > b.order) {
			return 1;
		}
		if (a.order < b.order) {
			return -1;
		}
		return 0;
	});
};

export const sortByDate = <T extends { createdAt: string | number }>(
	arr: T[]
) => {
	return arr
		.map((item) => {
			return {
				...item,
				createdAt: Number(item.createdAt),
			};
		})
		.sort((a: T, b: T) => {
			if (a.createdAt > b.createdAt) {
				return 1;
			}
			if (a.createdAt < b.createdAt) {
				return -1;
			}
			return 0;
		});
};

export const isUpperCase = (str: string) => /^[A-Z]*$/.test(str);

export const isCapitalized = (str: string) => isUpperCase(str.charAt(0));

export const getLanguageOptions = (filter?: (language: string) => boolean) => {
	const allLanguages: LanguagesList = languages;

	let langs = Object.keys(allLanguages);

	if (filter) {
		langs = langs.filter(filter);
	}

	return langs
		.filter((lang) => lang !== "en")
		.map((langKey) => ({
			label: allLanguages[langKey].name,
			value: langKey,
		}));
};

export const getEmbededUrl = (mediaLink: EmbededMedia) => {
	if (mediaLink.metaData?.player === "youtube") {
		return `https://www.youtube.com/embed/${mediaLink.filename}`;
	}

	return mediaLink.filepath;
};

export type ImageFolderTypes =
	| "course"
	| "profile"
	| "note"
	| "lesson"
	| "assets";

export const getImageFolder = (type: ImageFolderTypes) => {
	switch (type) {
		case "course":
			return COURSE_IMAGES_FOLDER || "";
		case "profile":
			return PROFILE_IMAGES_FOLDER || "";
		case "note":
			return NOTE_IMAGES_FOLDER || "";
		case "lesson":
			return LESSON_MEDIA_FOLDER || "";
		case "assets":
		default:
			return ASSETS_FOLDER || "";
	}
};

// 	const { mediaUrl: baseMediaUrl, onError: onMediaError } = useMediaLoader({
//	media: mediaData?.lesson?.lesson?.mediaLink,
// });
export const getImageSrc = (
	image?: EmbededMedia,
	size?: string,
	folderType?: ImageFolderTypes,
	awsBaseUrl = selectTheBestAWS()
) => {
	if (!image?.filepath) {
		return "/404";
	}

	if (image.filepath.indexOf("http") > -1) {
		return image.filepath;
	}

	if (!size || size === "main" || !folderType) {
		return awsBaseUrl + "/" + image.filepath;
	}

	const currentFolder = getImageFolder(folderType);

	const filename = image.filepath.replace(`${currentFolder}/`, "");

	return `${awsBaseUrl}/${currentFolder}/${size}-${filename}`;
};

export const getImageSrcSet = (
	image?: EmbededMedia,
	sizes?: string[],
	folderType?: ImageFolderTypes
) => {
	if (
		!image?.filepath ||
		image.filepath.indexOf("http") > -1 ||
		!sizes ||
		sizes.length === 0 ||
		!folderType
	) {
		return undefined;
	}

	const currentFolder = getImageFolder(folderType);

	const filename = image.filepath.replace(`${currentFolder}/`, "");

	const srcSet: string[] = [];

	//remove default ones from list

	sizes.slice(1).forEach((size, index) => {
		if (size === "main") {
			srcSet.push(
				`${selectTheBestAWS() + "/" + image.filepath} ${index + 2}x`
			);
		} else {
			srcSet.push(
				`${selectTheBestAWS()}/${currentFolder}/${size}-${filename} ${
					index + 2
				}x`
			);
		}
	});

	return {
		srcSet: srcSet.join(","),
	};
};

export const resetPlayer = () => {
	isPlayerReadyVar(false);
	playerPlayedSecondsTimeVar(0);
	playerDurationVar(0);
	playerCurrentFrameVar(0);
	isPlayerRepeatModeVar(false);
	isPlayerPlayingVar(false);
	playerSeekToVar(null);
	isPlayerMutedVar(false);
	playbackRateVar(1);
};

export const youtubeParser = (url: string) => {
	const regExp =
		/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	const match = url.match(regExp);
	return match && match[7].length == 11 ? match[7] : false;
};

export const getNewGainedStars = (
	unit: number,
	currPractice: PracticeType,
	newStars: number,
	stats?: LessonStats
): number => {
	let prevStars = 0;

	if (stats) {
		const practices = {
			speak: stats.speak || [],
			orderGame: stats.orderGame || [],
			write: stats.write || [],
			vocabulary: stats.vocabulary || [],
		};

		const practice = practices[currPractice];

		const currUnitItem = practice.find((i) => i.unit === unit);

		prevStars = currUnitItem?.stars || 0;
	}

	console.log("--newStars--", newStars);

	console.log("--prevStars--", prevStars);

	return newStars - prevStars;
};

type UserInfoArgs = {
	callback?: () => void;
	needToRnLogin?: boolean;
	currentUserInfo?: CurrentUser;
	prevUserInfo?: CurrentUser;
	analyticsLogin?: string;
	analyticsSignUp?: string;
};

export const updateUserInfo = ({
	callback,
	needToRnLogin,
	currentUserInfo,
	analyticsLogin, //"app_default"
	analyticsSignUp, //"app_default"
	prevUserInfo,
}: UserInfoArgs) => {
	if (!isEqual(currentUserInfo, prevUserInfo)) {
		if (analyticsSignUp) {
			if ((window as any).ReactNativeGoogleAnalytics) {
				(window as any).ReactNativeGoogleAnalytics(
					JSON.stringify({
						name: CUSTOM_SIGNUP_EVENT,
						params: {
							method: analyticsSignUp, //"app_default",
						},
					})
				);
			}
			trackEventPosthog(CUSTOM_SIGNUP_EVENT, {
				method: analyticsSignUp,
			});
		}

		if (analyticsLogin) {
			if ((window as any).ReactNativeGoogleAnalytics) {
				(window as any).ReactNativeGoogleAnalytics(
					JSON.stringify({
						name: "logLogin",
						params: {
							method: analyticsLogin, //"app_default",
						},
					})
				);
			}
			trackEventPosthog("logLogin", {
				method: analyticsLogin,
			});
		}

		if (callback) {
			callback();
		}

		if (needToRnLogin && currentUserInfo) {
			if ((window as any).ReactNativeWebAppLogin) {
				const info = JSON.stringify(currentUserInfo);
				(window as any).ReactNativeWebAppLogin(info);
			}
		}

		localStorage.setItem(
			"currentUserInfo",
			JSON.stringify(currentUserInfo)
		);

		userInfoVar(currentUserInfo);
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const logScreenView = (screenName: string, screenClass: string) => {
	console.log("-----logScreenView----", screenName);
	if ((window as any).ReactNativeGoogleAnalytics) {
		(window as any).ReactNativeGoogleAnalytics(
			JSON.stringify({
				name: "logScreenView",
				params: {
					screen_name: screenName,
					screen_class: screenClass,
				},
			})
		);
	}

	trackEventPosthog("logScreenView", {
		screen_name: screenName,
		screen_class: screenClass,
	});
};

export const logStreak = (streakNum: number) => {
	if ((window as any).ReactNativeGoogleAnalytics) {
		(window as any).ReactNativeGoogleAnalytics(
			JSON.stringify({
				name: CUSTOM_STREAK_EVENT,
				params: {
					streakNumber: streakNum,
				},
			})
		);
	}

	trackEventPosthog(CUSTOM_STREAK_EVENT, {
		streakNumber: streakNum,
	});
};

export const logCompletedUnit = ({
	unit,
	lessonId,
	chapterId,
	subCourseId,
	courseId,
}: {
	unit: number;
	lessonId: string;
	chapterId: string;
	subCourseId: string;
	courseId: string;
}) => {
	if ((window as any).ReactNativeGoogleAnalytics) {
		(window as any).ReactNativeGoogleAnalytics(
			JSON.stringify({
				name: CUSTOM_FINISH_UNIT_EVENT,
				params: {
					unit,
					lessonId,
					chapterId,
					subCourseId,
					courseId,
				},
			})
		);
	}

	trackEventPosthog(CUSTOM_FINISH_UNIT_EVENT, {
		unit,
		lessonId,
		chapterId,
		subCourseId,
		courseId,
	});
};

export const logGainingStars = ({
	userStars,
	type,
}: {
	userStars: number;
	type: "success" | "failed";
}) => {
	if ((window as any).ReactNativeGoogleAnalytics) {
		(window as any).ReactNativeGoogleAnalytics(
			JSON.stringify({
				name: CUSTOM_GAIN_STARS_EVENT,
				params: {
					starsCount: userStars,
					type,
				},
			})
		);
	}

	trackEventPosthog(CUSTOM_GAIN_STARS_EVENT, {
		starsCount: userStars,
		type,
	});
};

export function localizePrice(val: number) {
	const currency = i18n.locale === "fa-IR" ? "Toman" : "$";

	return translate("common.amount", {
		amount: i18n.toNumber(val, {
			precision: 0,
		}),
		currency,
	});
}

export type CURRENCY = "USD" | "IRT" | "TL";

export function getPriceCurrency(val: number, currency?: CURRENCY) {
	switch (currency) {
		case "TL":
			return translate("common.priceTl", {
				amount: i18n.toNumber(val, {
					precision: 2,
				}),
			});

		case "USD":
			return translate("common.priceUsd", {
				amount: i18n.toNumber(val, {
					precision: 2,
				}),
			});

		case "IRT":
		default:
			return translate("common.priceToman", {
				amount: i18n.toNumber(val, {
					precision: 0,
				}),
			});
	}
}

export function localizeNumber(val: number) {
	return i18n.toNumber(val, {
		precision: 0,
	});
}

export function convertToJalali(
	indate: number | string, //string just in this format MM-DD-YYYY
	showTime = true
) {
	const date =
		typeof indate === "string"
			? dayjs(indate || "", "MM-DD-YYYY")
			: dayjs(indate); //new Date(indate);

	const jDate = jalaali.toJalaali(
		date.get("year"),
		date.get("month") + 1,
		date.get("date")
	);

	let output = `${jDate.jy}/${jDate.jm}/${jDate.jd}`;

	if (showTime) {
		output += ` - ${date.get("hour")}:${date.get("minute")}`;
	}

	return output;
}

export function localizeDate(val: number) {
	if (i18n.locale !== "fa-IR") {
		return dayjs(val).format("D MMMM YYYY - HH:mm");
	}

	return convertToJalali(val);
}

export function getCurrentStreakCount(
	streakVal?: number | null,
	lastStreakDate?: string | null
) {
	if (!lastStreakDate) {
		return 0;
	}

	const today = dayjs().startOf("day");
	const streakDateObj = dayjs(lastStreakDate, "MM-DD-YYYY");
	const dayDifference = today.diff(streakDateObj, "day");

	if (dayDifference > 1) {
		return 0;
	} else {
		return streakVal || 0;
	}
}
