import { useMemo, useState } from "react";
import {
	CompleteMultipartUploadArgs,
	CompleteMultipartUploadData,
	CreateMultiPreSignedUrlArgs,
	CreateMultiPreSignedUrlData,
	CurrentUploadLocation,
	FileSpaceType,
	MediaItem,
	MultipartUploadType,
} from "../../graphQL/media/MediaTypes";
import { useMutation } from "@apollo/client";
import CREATE_MULTI_PRE_SIGNED_URL from "../../graphQL/media/CreateMultiPreSignedUrl";
import useErrorNotification from "../../helper/hooks/useErrorNotification";
import COMPLETE_MULTI_PART_UPLOAD from "../../graphQL/media/CompleteMultipartUpload";
import { uploadPartsInQueue } from "./utils/queue0uploader";
import { selectTheBestAWSLoc } from "../../services/operation-location-base";
import { IRAN_SERVER_LOCATION } from "../../services/servers.types";

interface MediaUploaderOptions {
	spaceType: FileSpaceType;
	type: MediaItem["type"];
	file: File;
	title: string;
	uploadType?: MultipartUploadType;
	languages?: string[];
	onProgress?: (uploadedBytes: number, totalBytes: number) => void;
	abortController?: AbortController;
	selectedLocation?: CurrentUploadLocation;
}

/**
 * TODO: need to improve
 * 1. add retry for video parts
 * 2. when video is uploading user can cancel the uploading
 * 3. if user exits the page then we have to cancel the uploading
 * 4. apply file validatin for front-end like back-end
 *    ---- max file size 50MB
 *    ---- support just some audio and video format
 * Future improvement
 * 5. in the future we can remove media which user uploads but not to use them
 */

export const useMediaUploader = () => {
	const [isFileUploading, setFileUploading] = useState(false);

	const [isFileUploadingFailed, setFileUploadingFailed] = useState(false);

	const [createPreSignedUrls, { data }] = useMutation<
		CreateMultiPreSignedUrlData,
		CreateMultiPreSignedUrlArgs
	>(CREATE_MULTI_PRE_SIGNED_URL);

	const dataForNotify = useMemo(() => {
		const res = {
			result: !!data?.createMultiPreSignedUrl?.data,
			errors: data?.createMultiPreSignedUrl?.errors,
		};

		return data ? res : undefined;
	}, [data]);

	useErrorNotification(dataForNotify);

	const [completeUpload, { data: completeData }] = useMutation<
		CompleteMultipartUploadData,
		CompleteMultipartUploadArgs
	>(COMPLETE_MULTI_PART_UPLOAD);

	const NotifyForComplete = useMemo(() => {
		const res = {
			result:
				!!completeData?.completeMultipartUpload?.media ||
				!!completeData?.completeMultipartUpload?.task,
			errors: completeData?.completeMultipartUpload?.errors,
		};
		return completeData ? res : undefined;
	}, [completeData]);

	useErrorNotification(NotifyForComplete);

	const handleMediaUpload = async ({
		file,
		title,
		spaceType,
		type: mediaType,
		uploadType = MultipartUploadType.Image,
		languages,
		onProgress,
		abortController,
		selectedLocation,
	}: MediaUploaderOptions) => {
		const bestAwsLoc = selectTheBestAWSLoc();

		const currentLocation = selectedLocation
			? selectedLocation
			: bestAwsLoc === IRAN_SERVER_LOCATION
			? CurrentUploadLocation.IR
			: CurrentUploadLocation.FRA;

		try {
			setFileUploading(true);

			if (!file) {
				throw new Error("File not found");
			}

			const fileExtension: string = file.name.split(".").pop()!;

			// (1) Create Multi PreSigned Urls in the backend server
			const preSignedUrlResp = await createPreSignedUrls({
				variables: {
					spaceType: spaceType,
					fileExtension,
					mimetype: file.type,
					fileSize: file.size,
					metadata: {
						filename: file.name,
						type: mediaType,
						title: title,
					},
					currentLocation,
				},
			});

			const preSignedUrlData =
				preSignedUrlResp.data?.createMultiPreSignedUrl?.data;

			if (
				preSignedUrlData?.urls &&
				preSignedUrlData?.urls.length > 0 &&
				preSignedUrlData?.uploadId
			) {
				// (2) Uploads parts of the video to the AWS server using the preSigned URLs
				const parts = await uploadPartsInQueue(
					file,
					preSignedUrlData.urls.map((url, index) => ({
						signedUrl: url,
						partNumber: index + 1,
					})),
					onProgress,
					abortController
				);

				if (parts.length === 0) {
					throw new Error("Parts not uploaded successfully");
				}

				// (3) Calls the CompleteMultipartUpload endpoint in the backend server
				const completeUploadResp = await completeUpload({
					variables: {
						spaceType: spaceType,
						uploadId: preSignedUrlData?.uploadId,
						parts: parts,
						filename: file.name,
						//this is file name for S3 please don't change it
						//it has to be from pre signed API
						filepath: preSignedUrlData.filename,
						type: mediaType,
						mimetype: file.type,
						title: title,
						currentLocation,
						uploadType,
						languages,
					},
				});

				const completeUploadData =
					completeUploadResp?.data?.completeMultipartUpload;

				// task
				if (completeUploadData?.media) {
					return completeUploadData.media;
				} else if (completeUploadData?.task) {
					return completeUploadData.task;
				} else {
					throw new Error("Media upload hasn't been completed!");
				}
			} else {
				throw new Error("Pre signed URLs not found");
			}
		} catch (err) {
			console.log("Error in handleMediaUpload", err);
			setFileUploadingFailed(true);
		} finally {
			setFileUploading(false);
		}
	};

	return {
		isFileUploading,
		isFileUploadingFailed,
		handleMediaUpload,
	};
};
