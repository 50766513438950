import { gql } from "@apollo/client";

const CREATE_MULTI_PRE_SIGNED_URL = gql`
	mutation CreateMultiPreSignedUrl(
		$spaceType: String!
		$mimetype: String!
		$fileExtension: String!
		$fileSize: Int!
		$metadata: MetadataInput!
		$currentLocation: CurrentLocation
	) {
		createMultiPreSignedUrl(
			spaceType: $spaceType
			mimetype: $mimetype
			fileExtension: $fileExtension
			fileSize: $fileSize
			metadata: $metadata
			currentLocation: $currentLocation
		) {
			data {
				urls
				filename
				uploadId
			}
			errors
		}
	}
`;

export default CREATE_MULTI_PRE_SIGNED_URL;
