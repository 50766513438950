import { gql } from "@apollo/client";

const COMPLETE_MULTI_PART_UPLOAD = gql`
	mutation CompleteMultipartUpload(
		$spaceType: String!
		$uploadId: String!
		$parts: [Multiparts!]!
		$filename: String!
		$type: String!
		$mimetype: String!
		$title: String!
		$filepath: String!
		$currentLocation: CurrentLocation
		$uploadType: UploadType
		$languages: [String]
	) {
		completeMultipartUpload(
			spaceType: $spaceType
			uploadId: $uploadId
			parts: $parts
			filename: $filename
			type: $type
			mimetype: $mimetype
			title: $title
			filepath: $filepath
			currentLocation: $currentLocation
			uploadType: $uploadType
			languages: $languages
		) {
			task {
				id
				status
			}
			media {
				id
				filename
				filepath
				type
				mimetype
				title
				createdBy
				createdAt
				updatedAt
			}
			errors
		}
	}
`;

export default COMPLETE_MULTI_PART_UPLOAD;
