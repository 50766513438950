import { gql } from "@apollo/client";

const BUY_PLAN = gql`
	mutation BuyPlan($planId: ID!, $couponCode: String) {
		buyPlan(planId: $planId, couponCode: $couponCode) {
			url
			errors
		}
	}
`;

export default BUY_PLAN;
