import { useReactiveVar } from "@apollo/client";
import * as React from "react";
import { onlyPremiumAccessVar, userInfoVar } from "../App";
import { Redirect, RouteProps } from "react-router-dom";
import { USER_SUBSCRIPTION_PREMIUM } from "../graphQL/user/UserConstants";
import UserAccessPage from "../components/userAccessPage/UserAccessPage";
import UserPremiumPage from "../components/userPremiumPage/userPremiumPage";
import Loading from "../components/loading/Loading";
import i18n from "i18n-js";

type Props = {
	component: any;
	componentProps?: React.ComponentProps<any>;
	access?: string;
} & RouteProps;

function ComponentPage(props: Props) {
	const { component: Component, componentProps, access, ...rest } = props;

	const cmpProps = componentProps || {};

	const onlyPremiumAccess = useReactiveVar(onlyPremiumAccessVar);

	return (
		<>
			{access ? (
				<UserAccessPage can={access}>
					<React.Suspense fallback={<Loading isLoading />}>
						<Component {...props} {...cmpProps} />
					</React.Suspense>
				</UserAccessPage>
			) : onlyPremiumAccess ? (
				<UserPremiumPage />
			) : (
				<React.Suspense fallback={<Loading isLoading />}>
					<Component {...props} {...cmpProps} />
				</React.Suspense>
			)}
		</>
	);
}

export function AutoNavigate(props: Props) {
	const { component: Component, componentProps, access, ...rest } = props;

	const userInfo = useReactiveVar(userInfoVar);

	// first: go to language page
	if (!userInfo?.nativeLanguage && rest.path !== "/language") {
		return (
			<Redirect
				to={{
					pathname: "/language",
				}}
			/>
		);
	} else if (!userInfo?.nativeLanguage) {
		return <ComponentPage {...props} />;
	}

	// second: go to select affilate page
	if (
		(!userInfo?.claimNoAffiliate ||
			userInfo?.claimNoAffiliate === "no-claim") &&
		rest.path !== "/select-affiliate"
	) {
		return (
			<Redirect
				to={{
					pathname: "/select-affiliate",
				}}
			/>
		);
	} else if (
		!userInfo?.claimNoAffiliate ||
		userInfo?.claimNoAffiliate === "no-claim"
	) {
		return <ComponentPage {...props} />;
	}

	if (i18n.locale !== "fa-IR") {
		return <ComponentPage {...props} />;
	}

	// third: go to select subscription plans page

	/*const isPremiumPlansPage = rest.path === "/subscription-plans";
	const isFreeUser = userInfo?.subscription !== USER_SUBSCRIPTION_PREMIUM;
	const hasUsedTrialVersion = userInfo?.hasUsedTrialVersion;

	if (!hasUsedTrialVersion && !isPremiumPlansPage && isFreeUser) {
		return (
			<Redirect
				to={{
					pathname: "/subscription-plans",
				}}
			/>
		);
	} else if (!hasUsedTrialVersion && isFreeUser) {
		return <ComponentPage {...props} />;
	}*/

	return <ComponentPage {...props} />;
}
