import { OperationVariables } from "@apollo/client";
import { ImageUploadItem } from "antd-mobile/es/components/image-uploader";
import { Task } from "../task/TaskTypes";

export type MediaItem = {
	id: string;
	filename: string;
	filepath: string;
	type: "image" | "video" | "audio" | "document";
	mimetype: string;
	title: string;
	createdBy: string;
	createdAt: string;
	updatedAt: string;
	__typename?: string;
	metaData?: {
		start?: string;
		end?: string;
		player?: string;
	};
};

//use for embeded media and media input
export type EmbededMedia = Omit<
	MediaItem,
	"createdBy" | "createdAt" | "updatedAt"
>;

export interface ImageUploadItemInternal extends ImageUploadItem {
	extra?: EmbededMedia;
}

export type RegisterMediaData = {
	registerMedia: {
		media: MediaItem;
		errors: string[];
	};
};

export type FileSpaceType =
	| "imageForCourse"
	| "imageForProfile"
	| "imageForNotes"
	| "mediaForLessons"
	| "imageDynamic";

export interface RegisterMediaArgs extends OperationVariables {
	spaceType: FileSpaceType;
	file: File;
	type: MediaItem["type"];
	title: string;
	fileSize: number;
}

export type FileMultiparts = {
	ETag: string;
	PartNumber: number;
};

export type CompleteMultipartUploadData = {
	completeMultipartUpload: {
		task?: Task;
		media?: MediaItem;
		errors: string[];
	};
};

export enum CurrentUploadLocation {
	FRA = "FRA",
	IR = "IR",
}

export enum MultipartUploadType {
	Image = "image",
	MultiMedia = "multiMedia",
	LessonMedia = "lessonMedia",
}

export interface CompleteMultipartUploadArgs extends OperationVariables {
	spaceType: FileSpaceType;
	uploadId: string;
	parts: FileMultiparts[];
	filename: string;
	type: MediaItem["type"];
	mimetype: string;
	title: string;
	filepath: string;
	currentLocation?: CurrentUploadLocation;
	uploadType?: MultipartUploadType;
	languages?: string[];
}

export type MediaMetadata = {
	filename: string;
	type: MediaItem["type"];
	title: string;
};

export type CreateMultiPreSignedUrlData = {
	createMultiPreSignedUrl: {
		data: {
			urls: string[];
			filename: string;
			uploadId: string;
		};
		errors: string[];
	};
};

export interface CreateMultiPreSignedUrlArgs extends OperationVariables {
	spaceType: FileSpaceType;
	fileExtension: string;
	mimetype: string;
	fileSize: number;
	metadata: MediaMetadata;
	currentLocation?: CurrentUploadLocation;
}

export type CreatePreSignedUrlData = {
	createPreSignedUrl: {
		url: string;
		errors: string[];
	};
};

export interface CreatePreSignedUrlArgs extends OperationVariables {
	spaceType: FileSpaceType;
	fileExtension: string;
	mimetype: string;
	fileSize: number;
	metadata: MediaMetadata;
}
