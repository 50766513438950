// this operations (resolvers) just need to call
import React from "react";
import { notification } from "antd";
import {
	currentActiveAwsStoragesVar,
	currentActiveServersVar,
	currentCountryVar,
} from "../App";
import {
	ABROAD_SERVER_LOCATION,
	ABROAD_SPECIFIC_OPERATIONS,
	BACKEND_SERVERS,
	IRAN_SERVER_LOCATION,
	IRAN_SPECIFIC_OPERATIONS,
	ServerLocation,
} from "./servers.types";
import Button from "../theme/button/Button";
import { translate } from "../i18n";
import { Link } from "react-router-dom";
import { CurrentUploadLocation } from "../graphQL/media/MediaTypes";

type NotificationType = "success" | "info" | "warning" | "error";

const messageComp = (message: string) => {
	return (
		<div>
			<div>{message}</div>
			{/*<Link to="/help-center">
				<Button color="info">
					{translate("serverNetworkProblems.contactSupport")}
				</Button>
	</Link>*/}
		</div>
	);
};

const openNotification = (
	type: NotificationType,
	notifType: "aws" | "server" = "server"
) => {
	const message =
		notifType === "server"
			? translate("serverNetworkProblems.serverProblem")
			: translate("serverNetworkProblems.awsProblem");

	const description =
		notifType === "server"
			? translate("serverNetworkProblems.serverUnreachable")
			: translate("serverNetworkProblems.aswUnreachable");

	notification[type]({
		placement: "bottom",
		message: message,
		description: messageComp(description),
		duration: 6,
	});
};

export const selectTheBestServerLoc = (
	operationName?: string,
	variables?: Record<string, any>
) => {
	if (operationName) {
		if (IRAN_SPECIFIC_OPERATIONS.includes(operationName)) {
			return IRAN_SERVER_LOCATION;
		}

		if (ABROAD_SPECIFIC_OPERATIONS.includes(operationName)) {
			return ABROAD_SERVER_LOCATION;
		}
	}

	// the multimedia and lessonMedia upload will do just by FRA Server
	if (
		variables &&
		operationName &&
		["CreateMultiPreSignedUrl", "CompleteMultipartUpload"].includes(
			operationName
		)
	) {
		if (variables.currentLocation === CurrentUploadLocation.FRA) {
			return ABROAD_SERVER_LOCATION;
		}
	}

	const activeServerLocs = currentActiveServersVar();

	if (activeServerLocs.length === 0) {
		openNotification("error", "server");

		return null; // No active servers
	}

	if (activeServerLocs.length === 1) {
		return activeServerLocs[0];
	}

	const country = currentCountryVar();

	if (country === "Iran") {
		return IRAN_SERVER_LOCATION;
	} else {
		return ABROAD_SERVER_LOCATION;
	}
};

export const selectTheBestServer = (
	operationName: string,
	variables?: Record<string, any>
) => {
	const selectedLoc = selectTheBestServerLoc(operationName, variables);

	if (selectedLoc) {
		return BACKEND_SERVERS.find((server) => server.location === selectedLoc)
			?.url;
	}

	return null;
};

export const selectTheBestAWSLoc = () => {
	const activeAWSs = currentActiveAwsStoragesVar();

	if (activeAWSs.length === 0) {
		openNotification("error", "aws");

		return null; // No active servers
	}

	if (activeAWSs.length === 1) {
		return activeAWSs[0];
	}

	const country = currentCountryVar();

	if (country === "Iran") {
		return IRAN_SERVER_LOCATION;
	} else {
		return ABROAD_SERVER_LOCATION;
	}
};

export const selectTheBestAWS = () => {
	const selectedLoc: ServerLocation | null = selectTheBestAWSLoc();

	if (selectedLoc) {
		return BACKEND_SERVERS.find((server) => server.location === selectedLoc)
			?.aws;
	}

	return null;
};
