import { MutationFunctionOptions, useMutation } from "@apollo/client";
import CB_START_TRANSACTION from "./cb-start-transaction.gql";
import {
	CBStartTransactionData,
	CBStartTransactionVariables,
} from "./cb-start-transaction.types";
import useErrorNotification from "../../../../helper/hooks/useErrorNotification";
import { useMemo } from "react";

export function useCBStartTransactionMutation() {
	const [cbStartTransaction, { loading, data }] = useMutation<
		CBStartTransactionData,
		CBStartTransactionVariables
	>(CB_START_TRANSACTION);

	const dataForNotify = useMemo(() => {
		const res = {
			result: !!data?.CBStartTransaction.result,
			errors: data?.CBStartTransaction?.errors,
		};

		return data ? res : undefined;
	}, [data]);

	useErrorNotification(dataForNotify);

	function cbStartTransactionHandler(
		variables: CBStartTransactionVariables,
		options?: MutationFunctionOptions<
			CBStartTransactionData,
			CBStartTransactionVariables
		>
	) {
		console.log(
			"CBStartTransactionAPIRequested",
			"Buy Plan API Requested!"
		);
		cbStartTransaction({
			variables,
			...options,
		});
	}

	return {
		loading,
		cbStartTransaction: cbStartTransactionHandler,
		data,
	};
}
