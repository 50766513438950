import { gql } from "@apollo/client";

const CB_START_TRANSACTION = gql`
	mutation CBStartTransaction($planId: ID!, $couponCode: String) {
		CBStartTransaction(planId: $planId, couponCode: $couponCode) {
			result
			discountToken
			transactionId
			errors
		}
	}
`;

export default CB_START_TRANSACTION;
