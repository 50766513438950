import React from "react";
import View from "../../../components/view/View";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import css from "./welcome-present-popup.module.css";
import Button from "../../../theme/button/Button";
import clsx from "clsx";
import Text from "../../../components/text/Text";
import { translate } from "../../../i18n";
import { useHandlePayment } from "../../../containers/ecommerce/hooks/handle-payment.hook";
import { usePostHog } from "posthog-js/react";

type Props = {
	test?: boolean;
};

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: require("./welcome-present.animation.json"),
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

const TRIAL_SUBSCRIPTION_ID = "667ddbbc09a511bfbebb95bf";
const TRIAL_SUBSCRIPTION_NAME = "trial_subscription";

// fill="#FD521B" --- orange
//  fill="#FFDE66"==== yellow
const WelcomePresentPopup = (props: Props) => {
	//const { chapterId, courseId, subcourseId } =
	//	useParams<CourseLessonUrlParams>();

	// const userInfo = useReactiveVar(userInfoVar);

	// const history = useHistory();

	const {
		handlePayment,
		buyPlanLoading,
		cbStartTransactionLoading,
		refetchUserLoading,
	} = useHandlePayment(TRIAL_SUBSCRIPTION_ID, TRIAL_SUBSCRIPTION_NAME);

	const posthog = usePostHog();

	const onGetGift = () => {
		posthog?.capture("trialPresentRequested");

		handlePayment();
	};

	return (
		<View className={css.container}>
			<View />
			<View className={css.startWrap}>
				<Lottie
					options={defaultOptions}
					height={300}
					width={300}
					isStopped={false}
					isPaused={false}
				/>

				<Text className={css.title}>
					{translate("welcomePresentPopup.pageTitle")}
				</Text>
				<Text className={css.description}>
					{translate("welcomePresentPopup.pageDescPer")}
					<b style={{ color: "red" }}>
						{" "}
						{translate("welcomePresentPopup.pageDescMain")}
					</b>{" "}
					{translate("welcomePresentPopup.pageDescAfter")}{" "}
				</Text>

				{/*<Button
					size="middle"
					className={css.btnDateRange}
					//onClick={onNextAction}
				>
					دریافت هدیه
				</Button>*/}
			</View>

			<motion.div
				animate={{
					scale: [0, 1],
					opacity: [0, 1],
				}}
				transition={{
					delay: 0.1,
				}}
				className={css.footer}
			>
				<Button
					size="large"
					className={clsx(css.btn)}
					onClick={onGetGift}
					loading={
						buyPlanLoading ||
						cbStartTransactionLoading ||
						refetchUserLoading
					}
					disabled={
						buyPlanLoading ||
						cbStartTransactionLoading ||
						refetchUserLoading
					}
					block
				>
					{translate("welcomePresentPopup.actionBtn")}
				</Button>
				{/*<Button
					size="large"
					className={clsx(css.btn, css.video)}
					onClick={goToLessonList}
					block
				>
					{translate("streak.lessonList")}
			</Button>*/}
			</motion.div>
		</View>
	);
};

export default WelcomePresentPopup;
