import { makeVar } from "@apollo/client";
import { PopupManagerTemplates } from "./popup-manager.constants";

export const currentPopupVar = makeVar<{
	template?: PopupManagerTemplates;
	title?: string;
	visible?: boolean;
} | null>(null);

export const PopupManagerState = {
	currentPopup: {
		read() {
			return currentPopupVar();
		},
	},
};
